import React, { useState } from "react";
import del from "./images/icon-6.png";
import view from "./images/icon-7.png";
import job from "./images/icon-9.png";
import sub from "./images/icon-10.png";
import edit from "./images/icon-5.png";

import "select2";
import Skeleton from "react-loading-skeleton";
import AdminListService from "../../../services/admin-list.service";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import { toast } from "react-toastify";


export default function NewAccountantIndex({
  accountantData,
  accountantLoading,
  getAccountant,
  setAccountantLoading,
  getCustomerWithId,
  delData,
  accountant_id,
}) {
  const [loading, setLoading] = useState(false);
  const [groupNo, setgroupNo] = useState("");
  const [customerModelView, setCustomerModelView] = useState([]);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("20");

  const viewCustomerModelPagination = (gpNo, e) => {
    setCurrentPage(e);
    let pageNo = e;
    const getPaginationData = async () => {
      setLoading(true);
      try {
        const responce = await AdminListService.viewCustomerInModel(
          gpNo,
          pageNo
        );

        let res;

        if (responce.status === 200) {
          res = responce.data.data.data;

          const results = [];

          res.map((value) => {
            return results.push({
              groupNo: value.groupNo,
              FirstName: value.FirstName,
              LastName: value.LastName,
              Address: value.Address,
              City: value.City,
              State: value.State,
              ZipCode: value.ZipCode,
              PhoneNumber: value.PhoneNumber,
              Email: value.Email,
              Denomination: value.Denomination,
              UserId: value.UserId,
              VoucherNumber: value.VoucherNumber,

              CreatedDate: value.CreatedDate,
            });
          });

          setCustomerModelView([...results]);
          setLimit(responce.data.data.per_page);
          setTotalResults(responce.data.data.total_records);
          setTotalPages(responce.data.data.total_pages);
          setCurrentPage(responce.data.data.current_page);

          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setCustomerModelView([]);
        setLoading(false);
      }
    };
    getPaginationData();
  };
  const deleteAccountant = async (groupId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",

      allowOutsideClick: false,
      showClass: {
        popup: `del-pop  
        animate__animated
        animate__fadeInUp
        animate__faster
        
        `,
        icon: "ques-ico",
      },
      hideClass: {
        popup: `
          del-pop  
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setAccountantLoading(true);

        try {
          const response = await AdminListService.deleteAccountantMain(groupId);
          if (response.status === 200) {
            toast.success("Deleted Successfully!", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            setTimeout(() => {
              getAccountant("load");
            }, 1500);
          }
        } catch (err) {
          toast.error("Something went wrong!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setAccountantLoading(false);
        }
      }
    });
  };
  const submitAccount = async (grpNo) => {
    try {
      setAccountantLoading(true);

      const response = await AdminListService.accountantSubmit(
        grpNo,
        accountant_id
      );

      if (response.status === 200) {
        toast.success("Submitted successfully!", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(() => {
          getAccountant("load");
        }, 500);
      }
    } catch (err) {
      console.log(err);

      setAccountantLoading(false);

      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.error(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong!", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const Table = ({ data }) => {
    return (
      <>
        <div className="table-responsive mt-3">
          <table className="table table-striped align-middle mb-0">
            <thead className="table-dark">
              <tr>
                <th width="16%">Company Name</th>
                <th width="15%">Date Submitted</th>
                <th width="16.6%">Total Customers</th>
                {/* <th>Remaining Customers Vouchers</th> */}
                <th width="9.45%">Job No.</th>
                <th>Bulk</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.length ? (
                <>
                  {data.map((el, index) => (
                    <tr key={index}>
                      <td>{el.Sitname}</td>
                      <td>{el.submited}</td>
                      <td>{el.totalCount}</td>
                      {/* <td>{el.Participants}</td> */}
                      <td>{el.jobNo}</td>
                      <td>{el.bulk}</td>
                      <td className="action-job">
                        <button
                          className="btn btn-outline-success"
                          data-bs-toggle="modal"
                          data-bs-target="#view_date_model_acc"
                          type="button"
                          onClick={() => {
                            setgroupNo(el.groupNo);
                            viewCustomerModelPagination(el.groupNo, "1");
                          }}
                        >
                          <img src={view} alt="missing" /> View
                        </button>
                        <button
                          className="btn btn-outline-danger"
                          type="button"
                          onClick={() => {
                            deleteAccountant(el.groupNo);
                          }}
                        >
                          <img src={del} alt="missing" /> Delete
                        </button>
                        <button
                          className="btn btn-outline-info"
                          data-bs-toggle="modal"
                          data-bs-target="#add_job_number"
                          type="button"
                          onClick={() => {
                            viewCustomerModelPagination(el.groupNo, "1");
                            setgroupNo(el.groupNo);
                          }}
                        >
                          <img src={job} alt="missing" /> Edit Voucher No.
                        </button>
                        <button
                          className="btn btn-outline-warning "
                          type="button"
                          onClick={() => {
                            submitAccount(el.groupNo);
                          }}
                        >
                          <img src={sub} alt="missing" /> Submit
                        </button>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan="7" align="center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const updateVoucher = async (event, row) => {
    const input = event.currentTarget;

    const newVoucherNumber = input.value;

    if (newVoucherNumber === event.target.defaultValue) {
      setEditingCell(null);
    } else {
      if (/^[a-zA-Z0-9]*$/.test(newVoucherNumber)) {
        if (newVoucherNumber.length >= 5 && newVoucherNumber.length <= 6) {
          try {
            const response = await AdminListService.voucherUpdate(
              newVoucherNumber,
              editingCell?.rowIndex,
              accountant_id
            );

            const updatedFormDataw = [...customerModelView];

            updatedFormDataw[row]["VoucherNumber"] = newVoucherNumber;
            setCustomerModelView(updatedFormDataw);

            // viewCustomerModelPagination(response.data.grpNo, currentPage);
            setEditingCell(null);
          } catch (err) {
            console.log(err);
            if (err.response?.status === 409) {
              toast.error(err?.response?.data?.message, {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (err.response?.status === 422) {
              toast.error("Something went wrong!", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          }
        } else {
          toast.error("Voucher length should be 5 or 6 characters.", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        toast.error("Voucher number must only contain letters and numbers", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }

    // Call API to update voucher number
  };

  // const updateInvoice = async (event) => {
  //   const input = event.currentTarget;

  //   const newInvoiceNumber = input.value;

  //   if (newInvoiceNumber === event.target.defaultValue) {
  //     setEditingCell(null);
  //   } else {
  //     try {
  //       const response = await AdminListService.invoiceUpdate(
  //         newInvoiceNumber,
  //         editingCell?.rowIndex,
  //         accountant_id,
  //         groupNo
  //       );

  //       if (response.status === 200) {
  //         viewCustomerModelPagination(groupNo, currentPage);
  //         setEditingCell(null);
  //       }
  //     } catch (err) {
  //       setEditingCell(null);

  //       if (err.response?.status === 409) {
  //         toast.error(err?.response?.data?.message, {
  //           position: "top-center",
  //           autoClose: 1500,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //       } else if (err.response?.status === 422) {
  //         toast.error("Something went wrong!", {
  //           position: "top-center",
  //           autoClose: 1500,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //       }
  //     }
  //   }

  //   // Call API to update voucher number
  // };

  // const updateCost = async (event) => {
  //   const input = event.currentTarget;

  //   const newCost = input.value;

  //   if (newCost === event.target.defaultValue) {
  //     setEditingCell(null);
  //   } else {
  //     try {
  //       const response = await AdminListService.costUpdate(
  //         newCost,
  //         editingCell?.rowIndex,
  //         accountant_id
  //       );

  //       if (response.status === 200) {
  //         viewCustomerModelPagination(groupNo, currentPage);
  //         setEditingCell(null);
  //       }
  //     } catch (err) {
  //       setEditingCell(null);
  //       if (err.response?.status === 409) {
  //         toast.error(err?.response?.data?.message, {
  //           position: "top-center",
  //           autoClose: 1500,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //       } else if (err.response?.status === 422) {
  //         toast.error(err?.response?.data?.message?.cost[0], {
  //           position: "top-center",
  //           autoClose: 1500,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //       }
  //     }
  //   }

  //   // Call API to update voucher number
  // };

  const [editingCell, setEditingCell] = useState(null);

  const handleEdit = async (e, rowIndex, type) => {
    if (e.target.tagName.toLowerCase() !== "input") {
      await setEditingCell({ rowIndex, type });

      document.getElementById(rowIndex + type)?.focus();
    }
  };

  const clearVoucher = async (grpNo) => {
    try {
      setLoading(true);

      const response = await AdminListService.voucherClear(grpNo);

      if (response.status === 200) {
        setLoading(false);

        viewCustomerModelPagination(grpNo, currentPage);
      }
    } catch (err) {
      setLoading(false);
      toast.error("Something went wrong!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // const clearInvoice = async (grpNo) => {
  //   try {
  //     setLoading(true);

  //     const response = await AdminListService.invoiceClear(grpNo);

  //     if (response.status === 200) {
  //       setLoading(false);

  //       viewCustomerModelPagination(grpNo, currentPage);
  //     }
  //   } catch (err) {
  //     setLoading(false);

  //     toast.error("Something went wrong!", {
  //       position: "top-center",
  //       autoClose: 1500,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //   }
  // };

  const DataForVoucher = ({ data }) => {
    return (
      <div className="table-responsive ">
        <table className="table table-striped align-middle w-100">
          <thead className="table-dark">
            <tr>
              <th>First Name</th>
              <th>Last Name </th>
              <th>Address </th>
              <th>City </th>
              <th>State</th>
              <th>Zip Code</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Denomination</th>
              <th>Voucher Number </th>

              <th>Date Created</th>
            </tr>
          </thead>
          <tbody>
            {data.length ? (
              <>
                {data.map((el, index) => (
                  <tr key={index}>
                    <td>{el.FirstName}</td>
                    <td>{el.LastName}</td>
                    <td>{el.Address}</td>
                    <td>{el.City}</td>
                    <td>{el.State}</td>
                    <td>{el.ZipCode}</td>
                    <td>{el.PhoneNumber}</td>
                    <td>{el.Email}</td>
                    <td>{el.Denomination}</td>

                    <td
                      className="cursor-pointer"
                      onClick={(e) => handleEdit(e, el.UserId, "voucher")}
                    >
                      {editingCell?.rowIndex === el.UserId &&
                      editingCell?.type === "voucher" ? (
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={el.VoucherNumber}
                          onBlur={(e) => updateVoucher(e, index)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              updateVoucher(e, index);
                            }
                          }}
                          id={el.UserId + "voucher"}
                        />
                      ) : (
                        el.VoucherNumber
                      )}
                    </td>

                    {/* <td onClick={(e) => handleEdit(e, el.UserId, "cost")}>
                      {editingCell?.rowIndex === el.UserId &&
                      editingCell?.type === "cost" ? (
                        <input
                          type="number"
                          className="form-control"
                          defaultValue={el.ClientCost}
                          onBlur={(e) => updateCost(e)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              updateCost(e);
                            }
                          }}
                          id={el.UserId + "cost"}
                        />
                      ) : (
                        el.ClientCost
                      )}
                    </td>
                    <td
                      onClick={(e) => handleEdit(e, el.UserId, "invoice")}
                      // onClick={(e) => {
                      //   handleTdClick(e, el.InvoiceNo, el.UserId, "invoice");
                      // }}
                    >
                      {editingCell?.rowIndex === el.UserId &&
                      editingCell?.type === "invoice" ? (
                        <input
                          type="number"
                          className="form-control"
                          defaultValue={el.InvoiceNo}
                          onBlur={(e) => updateInvoice(e)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              updateInvoice(e);
                            }
                          }}
                          id={el.UserId + "invoice"}
                        />
                      ) : (
                        el.InvoiceNo
                      )}
                    </td> */}

                    <td>{el.CreatedDate}</td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="13" align="center">
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            className="justify-content-center"
            onChange={(e) => {
              viewCustomerModelPagination(groupNo, e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  const CustomerModelTable = ({ data }) => {
    return (
      <>
        <div className="table-responsive ">
          <table className="table table-striped align-middle w-100">
            <thead className="table-dark">
              <tr>
                <th>First Name</th>
                <th>Last Name </th>
                <th>Address </th>
                <th>City </th>
                <th>State</th>
                <th>Zip Code</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Denomination</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.length ? (
                data.map((el, index) => (
                  <tr key={index}>
                    <td>{el.FirstName}</td>
                    <td>{el.LastName}</td>
                    <td>{el.Address}</td>
                    <td>{el.City}</td>
                    <td>{el.State}</td>
                    <td>{el.ZipCode}</td>
                    <td>{el.PhoneNumber}</td>
                    <td>{el.Email}</td>
                    <td>{el.Denomination}</td>
                    <td>
                      <button
                        className="btn p-0 btn-sm border-0"
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          getCustomerWithId(
                            el.UserId,
                            "accountant",
                            el.groupNo
                          );
                        }}
                      >
                        <img src={edit} className="edit" alt="missing" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm ms-2 border-0 p-0"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={async () => {
                          await delData(el.UserId, "accountant");
                        }}
                      >
                        <img src={del} className="deleted" alt="missing" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11" align="center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalResults > limit && totalPages > 1 ? (
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={parseInt(limit)}
              totalItemsCount={totalResults}
              className="justify-content-center"
              onChange={(e) => {
                viewCustomerModelPagination(groupNo, e);
              }}
              pageRangeDisplayed={8}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First Page"
              lastPageText="Last Page"
            />
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  return (
    <div className="uk-timeline-content">
      <div className="card ">
        <div className="card-header">Welcome Accountant</div>
        {accountantLoading ? (
          <Skeleton count={5} height="48px" />
        ) : (
          <Table data={accountantData} />
        )}
      </div>

      <div
        className="modal fade"
        id="add_job_number"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xxl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Customer Data</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {loading ? (
                <Skeleton count={3} height="48px" />
              ) : (
                <DataForVoucher data={customerModelView} />
              )}

              <div className="row">
                <div className="col-md-8 col-xl-10">
                  <div className="alert alert-danger alert-dismissible fade show mb-2 p-2 rounded-1">
                    <b className="fw-bold">Note! </b> Click the voucher number
                    for edit.
                    <button
                      type="button"
                      className="btn-close p-2"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>
                </div>
                <div className="col-md-4 ms-auto col-xl-2">
                  <button
                    className="btn btn-primary active mt-0"
                    onClick={() => {
                      clearVoucher(groupNo);
                    }}
                  >
                    Clear Voucher Number
                  </button>
                </div>
                {/* <div className="col-md-4 col-xl-2">
                  <button
                    className="btn btn-primary "
                    onClick={() => {
                      clearInvoice(groupNo);
                    }}
                  >
                    Clear Invoice Number
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="view_date_model_acc"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-xxl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Customer Data</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {loading ? (
                <Skeleton count={3} height="48px" />
              ) : (
                <CustomerModelTable data={customerModelView} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
