import React from "react";
import cityGift from "../Images/city_gift.png";
function GiftBox() {
  return (
    <div className="row">
      <div className="col-md-1">
        <img src={cityGift} alt="gift.png" width="55" height="56" />
      </div>

      {/* <div className="col-md-11" style={{ fontFamily: "Arial" }}>
        Once you receive your email from{" "}
        <a href={"mailto:" + process.env.REACT_APP_NO_REPLY}>
          {process.env.REACT_APP_NO_REPLY}
        </a>{" "}
        or
        <br />
        <a href={"mailto:" + process.env.REACT_APP_DESC_EMAIL}>
          {process.env.REACT_APP_DESC_EMAIL}
        </a>
         or you can use the card virtually, or request a mailed reward card.
      </div> */}
       <div className="col-md-11" style={{ fontFamily: "Arial" }}>
        Once you receive your email from{" "}
        {/* <a href={"mailto:" + process.env.REACT_APP_NO_REPLY}>
          {process.env.REACT_APP_NO_REPLY}
        </a>{" "} */}
        <a href={"mailto:" + process.env.REACT_APP_DESC_GIFTCARD}>
          {process.env.REACT_APP_DESC_GIFTCARD}
        </a>
        ,{" "}
        <a href={"mailto:" + process.env.REACT_APP_NO_REPLY}>
          {process.env.REACT_APP_NO_REPLY}
        </a>{" "}
        or{" "}
        <a href={"mailto:" + process.env.REACT_APP_DESC_EMAIL}>
          {process.env.REACT_APP_DESC_EMAIL}
        </a>{" "}
        then you can decide to use your reward card online or receive a physical
        card.
      </div>
    </div>
  );
}
export default GiftBox;
