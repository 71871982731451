import React, { useEffect, useState } from "react";

import AdminListService from "../../../services/admin-list.service";

import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";

import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import Swal from "sweetalert2";

import { useFormik } from "formik";
import { gersAddition } from "../../../schema";
import { useNavigate, useParams } from "react-router-dom";

export default function EditGers() {
  const [actionLoading, setActionLoading] = useState(true);

  const state = useSelector((state) => state.stateVals);
  const { id } = useParams();
  const { admin_id } = state;
  const navigate = useNavigate();
  useEffect(() => {
    if (admin_id) {
      const getAdminById = async () => {
        setActionLoading(true);
        try {
          const responce = await AdminListService.gersById(id);

          if (responce.status === 200) {
            setActionLoading(false);
            setValues({
              fname: responce?.data?.response?.fname || "",
              lname: responce?.data?.response?.lname || "",
              store_code: responce?.data?.response?.store_code || "",
              gers: responce?.data?.response?.gers || "",
            });
          }
        } catch (err) {
          setActionLoading(false);
          if (err?.response?.data?.message) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              text: err?.response?.data?.message,
              confirmButtonColor: "#e72e2e",
              confirmButtonText: "Try Again",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              iconColor: "#e04f5d",
              text: "Some thing went wrong!",
              confirmButtonColor: "#e72e2e",
              confirmButtonText: "Try Again",
            });
          }
        }
      };

      getAdminById();
    }

    // eslint-disable-next-line
  }, []);

  const updateOsr = async (values) => {
    setActionLoading(true);

    try {
      await AdminListService.updateSingleGers(values, id);

      setActionLoading(false);
      Swal.fire({
        icon: "success",
        title: "Updated Successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      setTimeout(() => {
        resetForm();
        navigate(-1);
      }, 1500);
    } catch (err) {
      console.log(err);
      setActionLoading(false);
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: err?.response?.data?.message,
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    resetForm,
    setValues,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      gers: "",
      fname: "",
      lname: "",
      store_code: "",
    },
    validationSchema: gersAddition,
    onSubmit: (values) => {
      updateOsr(values);
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Edit GERS</h5>
            </div>

            <div className="card-body">
              <form
                className="row g-2 row-cols-2 row-cols-lg-5"
                onSubmit={handleSubmit}
                noValidate
              >
                <div className="col">
                  <label className="form-label">
                    GERS Initials<span class="gl-form-asterisk"></span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="GERS Initials"
                    name="gers"
                    value={values.gers || ""}
                    onChange={handleChange}
                  />
                  {errors.gers && touched.gers ? (
                    <span className="text-danger">{errors.gers}</span>
                  ) : null}
                </div>
                <div className="col">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="fname"
                    value={values.fname || ""}
                    onChange={handleChange}
                  />
                  {errors.fname && touched.fname ? (
                    <span className="text-danger">{errors.fname}</span>
                  ) : null}
                </div>
                <div className="col">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    name="lname"
                    value={values.lname || ""}
                    onChange={handleChange}
                  />
                  {errors.lname && touched.lname ? (
                    <span className="text-danger">{errors.lname}</span>
                  ) : null}
                </div>

                <div className="col">
                  <label className="form-label">Store Code</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Store Code"
                    name="store_code"
                    value={values.store_code || ""}
                    onChange={handleChange}
                  />
                  {errors.store_code && touched.store_code ? (
                    <span className="text-danger">{errors.store_code}</span>
                  ) : null}
                </div>

                <div className="col">
                  <button className="btn btn-primary w-100 mt-lg-4">
                    Add GERS
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${actionLoading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
