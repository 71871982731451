import React from "react";
import { useState, useEffect } from "react";
import "./style.css";

import Footer2 from "./Footer2";
// import UserformSurvey from "./mattress_questionnare";
import { Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { Helmet } from "react-helmet";
import secureLocalStorage from "react-secure-storage";

// import axios from "axios";

function Index(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Register";
  const navigate = useNavigate();

  const submitForm = (e) => {
    e.preventDefault();
    localStorage.setItem("return_page", "true");
    navigate("/register/registration-form");
  };
  useEffect(() => {
    // if (localStorage.getItem("return_page") === "true") {
    //   navigate("/register/registration-form");
    // } else {
    //   localStorage.setItem("return_page", "false");
    // }
    localStorage.setItem("return_page", "false");
  }, []);

  const visited = () => {
    secureLocalStorage.setItem("visited", true);
  };
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        <div className="white-box">
          <ul className="points">
            <form
              method="post"
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submitForm}
            >
              {/* <h2 className="register_lower" style={{ textAlign: "center" }}>
                Raymour & Flanigan and Elite Rewards are proud sponsors of Make
                A Wish.
              </h2> */}
              <div className="row">
                <div className="col-md-12" id="text">
                  <p className="register-p">
                    Would you be interested in giving 8-10 minutes of your time
                    to improve your sleep?
                    <br />
                    You will get a $25 Reward Card when you complete a comfort
                    test.
                    {/* , and we'll also give a portion of the proceeds to Make
                    A Wish. */}{" "}
                    Just a few moments of your time can help the way you sleep.
                  </p>
                </div>
              </div>
              <br />
              <input
                // onClick="myTimeout()"
                type="submit"
                name="submit"
                id="submit"
                value="CONTINUE"
                className="sub-btn button"
                onClick={visited}
              ></input>
            </form>
          </ul>
        </div>
      </div>

      <Footer2 />
    </>
  );
}
export default Index;
