import React from "react";
import cityLook from "../Images/city_look.png";
function EmailBox() {
  return (
    <div className="row">
      <div className="col-md-1">
        <img src={cityLook} alt="look.png" width="55" height="57" />
      </div>

      {/* <div className="col-md-11">
        <h2>BE ON THE LOOK OUT!</h2>
        Expect an email within 5 business days from{" "}
        <a href={"mailto:" + process.env.REACT_APP_NO_REPLY}>
          {process.env.REACT_APP_NO_REPLY}
        </a>{" "}
        or
        <br />
        <a href={"mailto:" + process.env.REACT_APP_DESC_EMAIL}>
          {process.env.REACT_APP_DESC_EMAIL}
        </a>{" "}
        we suggest adding these email addresses to your SAFE SENDER list in your
        email settings.
      </div> */}
           <div className="col-md-11">
        <h2>BE ON THE LOOK OUT!</h2>
        Expect an email(s) within 5 business days from{" "}
        <a href={"mailto:" + process.env.REACT_APP_DESC_GIFTCARD}>
          {process.env.REACT_APP_DESC_GIFTCARD}
        </a>
        ,{" "}<br/>
        <a href={"mailto:" + process.env.REACT_APP_NO_REPLY}>
          {process.env.REACT_APP_NO_REPLY}
        </a>{" "}
        or{" "}
        <a href={"mailto:" + process.env.REACT_APP_DESC_EMAIL}>
          {process.env.REACT_APP_DESC_EMAIL}
        </a>{" "}
        <br />
        Please add these email addresses to your safe senders list in your email
        setting.
        <br />
        Once you receive your email(s) you may use the reward virtually or
        request plastic cards to be mailed.
      </div>
    </div>
  );
}

export default EmailBox;
